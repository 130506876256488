import './App.css';
import axios from 'axios';
import {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function App() {
  const MySwal = withReactContent(Swal)

  let [answerCollector] = useState([])
  let [questions, setQuestions] = useState(null);
  let fetchData = async () => {
    let response = await axios.get(
      'https://192.145.37.66/api/Questionnaire/GetAllQuestions'
    );
    // console.log(response.data);
    setQuestions(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // post method
  const today = new Date();
  const url = 'https://192.145.37.66/api/Questionnaire/SendForm';
  const [postData, setPostData] = useState({
    FirstName: "",
    LastName: "",
    email: "",
    answers: answerCollector
  });
  async function  submit(e) {
    e.preventDefault();
    // console.log(postData);
    await axios.post(url, {
      FirstName: postData.FirstName,
      LastName: postData.LastName,
      email: postData.email,
      answers: answerCollector
    }).catch(error => {
      console.log(error.response);
      MySwal.fire({
        title: <strong>{error.response.data.title}</strong>,
        html: <i>Please try again in {error.response.data.minutes} minutes</i>,
        icon: 'error'
      })
    }).then(ress => {
      console.log(ress.data);
      MySwal.fire({
        title: <strong>{ress.data}</strong>,
        // html: <i>One of our team members will reach out to you shortly.</i>,
        icon: 'success'
      })
    })
  }

  function handle(e) {
    const newData = { ...postData };
    newData[e.target.id] = e.target.value;
    setPostData(newData);

    console.log(newData);
  }

  function PushAnswer(id , value){
    let exists = false;
    let i = 0;
    for(; i < answerCollector.length; i++){
      if(answerCollector[i].questionId === id){
        exists = true;
        break;
      }
    }
    if(exists){
      answerCollector.splice(i,1)
    }
    answerCollector.push({questionId : id, answer: value});
  }

  if(questions != null)
    return (
      <div className="app">
        <form onSubmit={(e) => submit(e)}>
          <div className="questionnaire-head">
            <a href="https://gshub.co/pages/Services.html"><img src={require('./arrowLeft.png')} alt="" />Back to Home</a>
            <div className="questionnaire-head-title">
              <h3>Questionnaire</h3>
            </div>
          </div>
          <div className="common-input">
            <label htmlFor="FirstName">Firstname:</label>
            <input type="text" id="FirstName" name="FirstName" autoComplete="off" value={postData.FirstName} onChange={(e) => handle(e)} required />
          </div>
          <div className="common-input">
            <label htmlFor="LastName">Lastname:</label>
            <input type="text" id="LastName" name="LastName" autoComplete="off" value={postData.LastName} onChange={(e) => handle(e)} required />
          </div>
          <div className="common-input">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={postData.email} onChange={(e) => handle(e)} required />
          </div>
          {questions.map((question) => {
              switch(question.questionType){
                case 101:
                  return (
                    <div className="dropdown-datalist" key={question.id}>
                      <label htmlFor={question.id}>{question.title}</label>
                      <input type="text" list={question.id} autoComplete="off" value={postData.answers.answer} onChange={(e) =>PushAnswer(question.id,e.target.value )} required />
                        <datalist id={question.id} name={question.id}>
                        {question.answers.map((answer) => {
                            return (<option key={answer.id} value={answer.title} />)
                        })}
                      </datalist>
                    </div>
                  )
                case 1: 
                  return (
                    <div className="multiple-choice" key={question.id}>
                      <p>{question.title}</p>
                      {question.answers.map((answer) => {
                            return (<label key={answer.id}><input type="radio" name={question.id} value={answer.title}
                             onChange={(e) => PushAnswer(question.id,e.target.value )} required />{answer.title}</label>)
                      })}
                    </div>
                  )
                default:
              }
              return (
                <div></div>
              )
          })}
          <button type="submit" value="Submit">Submit</button>
        </form>
      </div>
    );
    // console.log(answerCollector)
}

export default App;